<template>
  <div class="d-flex flex-wrap">
    <v-container class="d-flex flex-xs-column flex-wrap">
      <v-col md="3" class="d-flex flex-column">
        <div>
          <p class="title">
            {{
              this.$vuetify.lang.t(
                "$vuetify.main.patientDashboard.main.yourTherapist"
              )
            }}
          </p>
          <p v-if="getFullNamePsychologist" class="align-self-center">
            {{ getFullNamePsychologist }}
          </p>
          <p v-else class="align-self-center">
            {{
              this.$vuetify.lang.t(
                "$vuetify.main.patientDashboard.main.nonTherapist"
              )
            }}
          </p>

          <v-btn
            class="align-self-md-center"
            color="primary"
            depressed
            to="/dashboard/psychologist"
          >
            {{
              getFullNamePsychologist
                ? this.$vuetify.lang.t(
                    "$vuetify.main.patientDashboard.main.switchTherapist"
                  )
                : this.$vuetify.lang.t(
                    "$vuetify.main.patientDashboard.main.chooseTherapist"
                  )
            }}
          </v-btn>
        </div>
      </v-col>
      <v-col md="6" align="center">
        <WebRTCPlayer
          v-if="psychologistInfo"
          ref="webRTC"
          :next-session="nextSessionInfo"
        ></WebRTCPlayer>
        <div v-else class="web-rtc-substitute">
          <span class="white--text">{{
            this.$vuetify.lang.t(
              "$vuetify.main.psychologistDashboard.mainPage.webRTC.textInformationInVideoPlayer"
            )
          }}</span>
        </div>
        <div class="d-flex justify-center flex-wrap mt-5">
          <p v-if="!paymentMethod">
            {{
              this.$vuetify.lang.t(
                "$vuetify.main.patientDashboard.main.paymentMethodWarning"
              )
            }}
          </p>
          <v-btn v-if="!paymentMethod" small @click="linkToPayment">{{
            this.$vuetify.lang.t(
              "$vuetify.main.patientDashboard.main.paymentBtn"
            )
          }}</v-btn>
          <p class="mt-3">
            {{
              this.$vuetify.lang.t(
                "$vuetify.main.patientDashboard.main.warningSession1"
              )
            }}
            <Terms>{{
              $vuetify.lang.t(
                "$vuetify.main.patientDashboard.main.userAgreementLink"
              )
            }}</Terms>
          </p>

          <v-row class="gap mt-2" justify="center">
            <v-btn
              v-if="psychologistInfo"
              depressed
              color="primary"
              @click="resheduleSession"
            >
              {{
                getNextSessionId
                  ? this.$vuetify.lang.t(
                      "$vuetify.main.patientDashboard.main.shiftSession"
                    )
                  : this.$vuetify.lang.t(
                      "$vuetify.main.patientDashboard.main.bookedSession"
                    )
              }}
            </v-btn>
            <v-btn
              v-if="getNextSessionId"
              depressed
              color="warning"
              @click="cancelSession({ isBtn: true })"
            >
              {{
                this.$vuetify.lang.t(
                  "$vuetify.main.patientDashboard.main.cancelSession"
                )
              }}
            </v-btn>
          </v-row>
        </div>
      </v-col>
    </v-container>
    <v-container>
      <v-col cols="12" md="10" offset-md="1">
        <template v-if="nextSessionInfoAproved">
          <v-row
            v-for="(item, index) in getTimeSlotsForCurrentPsychologist"
            :key="index"
          >
            <v-col cols="12" md="12">
              <span>{{ item.date.translated }}</span>
              <div class="d-flex flex-wrap">
                <v-btn
                  v-for="(slots, i) in item.slots"
                  :key="slots.id"
                  @click="dataToEmit({ slots, date: item.date }, i, $event)"
                  class="ml-2 mt-2"
                  color="primary"
                  outlined
                  :class="{
                    'btn-disable': slots.id === getNextSessionId,
                  }"
                  :disabled="slots.is_booked || !slots.available"
                  id="custom-disabled"
                >
                  {{ slots.time }}
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <v-btn
                depressed
                color="primary"
                @click="cancelResheduleSession"
                class="mt-5"
              >
                {{
                  this.$vuetify.lang.t(
                    "$vuetify.main.patientDashboard.main.cancelSession"
                  )
                }}
              </v-btn>
            </v-col>
            <v-col cols="12" md="8">
              <v-btn
                v-if="selectedSlot"
                depressed
                color="primary"
                class="mt-5"
                @click="updateTimeSlot(selectedSlot.slots.id)"
              >
                {{
                  getNextSessionId
                    ? this.$vuetify.lang.t(
                        "$vuetify.main.patientDashboard.main.shiftSessionOnSelectedTime"
                      )
                    : this.$vuetify.lang.t(
                        "$vuetify.main.patientDashboard.main.bookedSessionOnSelectedTime"
                      )
                }}
                {{ selectedSlot.slots.time }}
                {{ selectedSlot.date.translated }}
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </v-col>
    </v-container>
  </div>
</template>

<script>
import PersonalData from "@/components/UI/personalTerms/personalData";

const Terms = () => import("@/components/UI/personalTerms/terms");

import { mapGetters } from "vuex";
import Vue from "vue";
const WebRTCPlayer = () => import("../WebrtcPlayer/WebRTCPlayer");
export default {
  name: "patientMainPage",
  // eslint-disable-next-line vue/no-unused-components
  components: { Terms, WebRTCPlayer, PersonalData },
  metaInfo() {
    return {
      title:
        "Платформа / " + this.$vuetify.lang.t("$vuetify.main.header.menu.main"),
    };
  },
  data() {
    return {
      verifCard: !!this.$store.getters["user/getUser"].payment_method.id,
      prevBtn: null,
      patientName: "Господин",
      // psychologistInfo: {},
      resheduleSessionAvailable: true,
      activeSlot: null,
      selectedSlot: null,
      selectedSlotBtn: null,
      nextSessionInfoAproved: false,
      nextSessionInfo: "",
    };
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
      psychologistInfo: "user/getAttachedPsychologist",
    }),
    paymentMethod: function () {
      return this.user.payment_method.id;
    },
    getFullNamePsychologist() {
      if (!this.psychologistInfo) return false;
      return this.psychologistInfo.surname + " " + this.psychologistInfo.name;
    },
    getTimeSlotsForCurrentPsychologist: function () {
      return this.psychologistInfo.time_slots;
    },
    getFullNamePatient: function () {
      return this.user.name;
    },
    getNextSessionId: function () {
      if (this.user.time_slots[0]) {
        return this.user.time_slots[0].slots[0].id;
      } else {
        return "";
      }
    },
  },
  // watch: {
  //   user: function () {
  //     this.setPsychologistInfoFromState();
  //   },
  // },
  mounted() {
    this.$store.dispatch("user/getProfile", {});
    // this.setPsychologistInfoFromState();
  },
  methods: {
    linkToPayment() {
      this.$router.push("/dashboard/payment");
    },
    // setPsychologistInfoFromState() {
    //   this.psychologistInfo = this.user.attached_psychologist;
    // },
    resheduleSession() {
      this.nextSessionInfoAproved = !this.nextSessionInfoAproved;
    },
    cancelResheduleSession() {
      this.nextSessionInfoAproved = false;
    },
    dataToEmit(item, i, event) {
      this.selectedSlot = item;

      this.activeSlot = i;
      this.$emit("userSelect", this.selectedSlot);

      if (this.prevBtn) {
        this.prevBtn.classList.remove("active");
      }

      if (event.target.tagName === "SPAN") {
        this.prevBtn = event.target.parentElement;
        event.target.parentElement.classList.toggle("active");
      } else {
        this.prevBtn = event.target;
        event.target.classList.toggle("active");
      }
    },
    async cancelSession() {
      Vue.swal({
        target: document.getElementById("main"),
        text: this.$vuetify.lang.t(
          "$vuetify.main.patientDashboard.main.swallCancelSession"
        ),
        toast: true,
        position: "top-right",
        showConfirmButton: false,
        didOpen: () => {
          Vue.swal.showLoading();
        },
        didClose: () => {},
      });

      await this.$store
        .dispatch("user/cancelTimeSlotByPatient", {
          id: this.getNextSessionId,
        })
        .then((response) => {
          if (response.status === 200) {
            this.$store.dispatch("user/getProfile", {});

            this.$refs.webRTC.getNextSession();

            Vue.swal({
              target: document.getElementById("main"),
              text: response.data.message,
              icon: "success",
              timer: 4000,
              toast: true,
              position: "top-right",
              showConfirmButton: false,
            });
          }
        })
        .finally(() => {});
    },
    async updateTimeSlot(id) {
      if (this.getNextSessionId) {
        await this.cancelSession().then(() => {});
        await this.bookNewTimeSlot(id);
        await this.$store.dispatch("user/getProfile", {});
      } else {
        await this.bookNewTimeSlot(id);
      }
      this.selectedSlot = null;
      this.nextSessionInfoAproved = false;
    },
    async bookNewTimeSlot(id) {
      Vue.swal({
        target: document.getElementById("main"),
        text: this.$vuetify.lang.t(
          "$vuetify.main.patientDashboard.main.swallBookedSession"
        ),
        toast: true,
        position: "top-right",
        showConfirmButton: false,
        didOpen: () => {
          Vue.swal.showLoading();
        },
      });

      await this.$store
        .dispatch("user/bookTimeSlotByPatient", { id })
        .then((response) => {
          if (response.status === 200) {
            Vue.swal({
              target: document.getElementById("main"),
              text: response.data.message,
              icon: "success",
              timer: 4000,
              toast: true,
              position: "top-right",
              showConfirmButton: false,
            });
          }
        });

      await this.$store.dispatch("user/getProfile", {});

      this.$refs.webRTC.getNextSession();
    },
  },
};
</script>

<style scoped>
.web-rtc-substitute {
  min-width: 300px;
  max-width: 700px;
  height: 350px;
  width: 100%;
  padding: 0 20px 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background-color: #262626;
}
#custom-disabled + .v-btn--disabled {
  color: #bcbcbc !important;
}

.active {
  color: #ffffff !important;
  background: repeating-linear-gradient(
    -60deg,
    #00a3b6,
    #00a3b6 10px,
    #008192 10px,
    #008192 20px
  );
  border: 1px solid #00a3b6;
}

.text-color-gray {
  color: #313131;
}

#custom-disabled + .btn-disable {
  color: #ffffff !important;
  background-color: #00a3b6;
}

.card-warning {
  border-radius: 15px;
  max-width: 60%;
  padding: 30px;
  background-color: #d9e5ec;
}

.gap {
  gap: 1rem;
}
</style>
